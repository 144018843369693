import React from "react";

// Import the Project Screenshots
import wordle from "../assets/projects/wordle.png";
import onlinemarketplace from "../assets/projects/onlinemarketplace.png";
import youtube from "../assets/projects/youtube.png";
import shopifychallenge from "../assets/projects/shopifychallenge.png";
import personalwebsite from "../assets/projects/personalwebsite.png";

// Import projects.css stylesheet
import "../styles/projects.css"



// The Projects component in the App
const Projects = () => {

  // Array of Project Items
  const projects = [
    {
      id: 1,
      src: wordle,
      title: "2 Player Wordle",
      text: "2 Player Wordle allows you and a friend to play Wordle head-to-head. Written using JavaScript, React, Node.js and Socket.io by Michael Wang and Daniel Conte.",
      github: "https://github.com/michaelwangcode/2-player-wordle",
    },
    {
      id: 2,
      src: onlinemarketplace,
      title: "Online Marketplace",
      text: "An online marketplace that allows users to list, delete and search through products. Written using JavaScript, Node.js, jQuery and PostgreSQL in collaboration with two other students at Lighthouse Labs.",
      github: "https://github.com/bahar-hosseini/buy-sell-listing-website",
    },
    {
      id: 3,
      src: youtube,
      title: "YouTube Home Page Clone",
      text: "This YouTube home page clone was made during the Complete HTML and CSS course by SuperSimpleDev. Written and formatted using HTML and CSS.",
      github: "https://github.com/michaelwangcode/html-css-course",
    },
    {
      id: 4,
      src: shopifychallenge,
      title: "Shopify iOS Developer Intern Challenge",
      text: "The iOS Developer Intern Challenge that resulted in an interview with Shopify in 2019. Written using Swift and Alamofire.",
      github: "https://github.com/michaelwangcode/shopify-ios-challenge",
    },
    {
      id: 5,
      src: personalwebsite,
      title: "Personal Website",
      text: "The website you're viewing right now! Written using JavaScript, React, Tailwind CSS and standard CSS.",
      github: "https://github.com/michaelwangcode/michael-wang-website",
    },
  ];



  // Return the Projects component
  return (

    /* PROJECTS COMPONENT */
    <div name="projects" className="bg-white text-black pt-12 pb-12">

      {/* Container for project title, description and thumbnails */}
      <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
        
        {/* Projects Header */}
        <p className="text-4xl font-bold">
          Projects
        </p>

        {/* Use the map function to loop through and display every image in the projects array using EJS */}
        {projects.map(({ id, src, title, text, github }) => (

          <div key={id} class="projects-box">
            <div class="projects-image-box">
              <img src={src} alt=""/>
            </div>

            <div class="projects-text-box">
              <div class="projects-title">
                {title}
              </div>
              <div class="projects-description">
                {text}
              </div>
              <div class="projects-link">
                <a href={github}>
                  GitHub &#187;
                </a>
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
};



export default Projects;

