import React from "react";

// Import all of the thumbnails for the technologies we use
import html from "../assets/skills/html.png";
import css from "../assets/skills/css.png";
import javascript from "../assets/skills/javascript.png";
import reactImage from "../assets/skills/react.png";
import python from "../assets/skills/python.png";
import matlab from "../assets/skills/matlab.png";
import sql from "../assets/skills/sql.png";
import java from "../assets/skills/java.png";
import github from "../assets/skills/github.png";
import firebase from "../assets/skills/firebase.png";
import swift from "../assets/skills/swift.png";
import ruby from "../assets/skills/ruby.png";



// The Skills component in the app
const Skills = () => {

  // Array of Technologies in the Skills section
  // Each technology item has an id, image, title and html color style
  const techs = [
    {
      id: 1,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
      id: 2,
      src: reactImage,
      title: "React",
      style: "shadow-blue-300",
    },
    {
      id: 3,
      src: python,
      title: "Python",
      style: "shadow-yellow-400",
    },
    {
      id: 4,
      src: java,
      title: "Java",
      style: "shadow-red-400",
    },
    {
      id: 5,
      src: html,
      title: "HTML",
      style: "shadow-orange-500",
    },
    {
      id: 6,
      src: css,
      title: "CSS",
      style: "shadow-blue-500",
    },
    {
      id: 7,
      src: swift,
      title: "Swift",
      style: "shadow-orange-400",
    },
    {
      id: 8,
      src: ruby,
      title: "Ruby",
      style: "shadow-red-400",
    },
    {
      id: 9,
      src: sql,
      title: "SQL",
      style: "shadow-green-400",
    },

    {
      id: 10,
      src: firebase,
      title: "Firebase",
      style: "shadow-orange-300",
    },
    {
      id: 11,
      src: github,
      title: "GitHub",
      style: "shadow-gray-400",
    },
    {
      id: 12,
      src: matlab,
      title: "MATLAB",
      style: "shadow-orange-400",
    },
  ];



  // Return the Skills component
  return (

    /* SKILLS COMPONENT */
    <div name="skills" className="bg-white w-full h-fit pt-12 pb-12">

      {/* Container for skills title, description and icons */}
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-black">
        
        {/* Container for title and description */}
        <div>

          {/* Skills title */}
          <p className="text-4xl font-bold inline">
            Skills
          </p>
        </div>

        {/* Container of Skills Thumbnails */}
        <div className="w-full grid grid-cols-3 sm:grid-cols-4 sm:gap-8 gap-2 text-center py-8 sm:px-0">
          
          {/* Display skills thumbnails */}
          {/* Set the id and src for the thumbnail image */}
          {techs.map(({ id, src, title, style }) => (
            
            /* Container for the individual thumbnail image and text */
            <div key={id} className={`shadow-md py-2 ${style}`}>
              
              {/* Image */}
              <img src={src} alt={title} className="w-20 mt-3 mx-auto" />
             
              {/* Title */}
              <p className="mt-4 mb-2 text-xl font-medium">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default Skills;
