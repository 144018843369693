// Import all of the Components of the App
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Education from "./components/Education";
import Volunteer from "./components/Volunteer";
import Experience from "./components/Experience";



// The main App
function App() {

  // The components of the app
  return (

    <div>
      <NavBar />
      <Home />
      <Projects />
      <Skills />
      <Experience />
      <Education />
      <Volunteer />
      <Contact />
    </div>
  );
}



export default App;
