import React from "react";

// Import the Volunteer Logo Images
import StallionImage from "../assets/volunteer/stallion.png";
import FashionArtTorontoImage from "../assets/volunteer/fashionarttoronto.png";
import TorontoFashionWeekImage from "../assets/volunteer/torontofashionweek.png";
import CompsaImage from "../assets/volunteer/compsa.png";
import SickKidsImage from "../assets/volunteer/sickkids.png";

// Import volunteer.css stylesheet
import "../styles/volunteer.css"



// The Volunteer component in the app
const Volunteer = () => {

  // Array of Volunteer Items
  const volunteer = [
    {
      id: 1,
      src: FashionArtTorontoImage,
      company: "Fashion Art Toronto",
      position: "Production Volunteer",
      location: "Toronto, Canada",
      year: "2022-2023",
    },
    {
      id: 2,
      src: StallionImage,
      company: "Stallion Men's Fashion",
      position: "Runway Model",
      location: "Toronto, Canada",
      year: "2022",
    },
    {
      id: 3,
      src: TorontoFashionWeekImage,
      company: "Toronto Fashion Week",
      position: "Production Volunteer",
      location: "Toronto, Canada",
      year: "2018-2019",
    },
    {
      id: 4,
      src: CompsaImage,
      company: "Queen's Computing Students' Association (COMPSA)",
      position: "Equity & Diversity Commissioner",
      location: "Kingston, Ontario, Canada",
      year: "2015-2016",
    },
    {
      id: 5,
      src: SickKidsImage,
      company: "SickKids Hospital",
      position: "Administrative Volunteer - Division of Nephrology",
      location: "Toronto, Canada",
      year: "2015",
    },
  ];


  
  // Return the Volunteer component
  return (

    /* VOLUNTEER COMPONENT */
    /* Styled using Tailwind */
    <div name="volunteer" className="w-full h-fit bg-white text-black pt-12 pb-12">
      
      {/* Title and Paragraph Container */}
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">

        {/* Volunteer Header */}
        <p className="text-4xl font-bold">
          Volunteer
        </p>

        {/* Use the map function to loop through volunteer array using EJS */}
        {volunteer.map(({ id, src, company, position, location, year }) => (

          <div key={id} class="volunteer-box">
            <div class="volunteer-image-box">
              <img src={src} alt={company}/>
            </div>

            <div class="volunteer-text-box">
              <div class="volunteer-company">
                {company}
              </div>
              <div class="volunteer-position">
                {position}
              </div>
              <div class="volunteer-location">
                {location}
              </div>
              <div class="volunteer-year">
                {year}
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
};



export default Volunteer;

