import React from "react";

// Import the Experience Logo Images
import MedsOnWheels from "../assets/experience/medsonwheels.png"

// Import experience.css stylesheet
import "../styles/experience.css"



// The Experience component in the app
const Experience = () => {


  // Array of Experience Items
  const experience = [
    {
      id: 1,
      src: MedsOnWheels,
      company: "MedsOnWheels",
      position: "Full Stack Developer",
      location: "Toronto, Canada (Remote)",
      date: "Feb 2023 - Present",
    },
  ];


  // Return the Experience component
  return (

    /* EXPERIENCE COMPONENT */
    /* Styled using Tailwind */
    <div name="experience" className="w-full h-fit bg-white text-black pt-12 pb-12">

      {/* Title and Paragraph Container */}
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">

        {/* Experience Header */}
        <p className="text-4xl font-bold">
          Experience
        </p>

        {/* Use the map function to loop through experience array using EJS */}
        {experience.map(({ id, src, company, position, location, date }) => (

          <div key={id} class="experience-box">
            <div class="experience-image-box">
              <img src={src} alt={company}/>
            </div>

            <div class="experience-text-box">
              <div class="experience-company">
                {company}
              </div>
              <div class="experience-position">
                {position}
              </div>
              <div class="experience-location">
                {location}
              </div>
              <div class="experience-date">
                {date}
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
};



export default Experience;


