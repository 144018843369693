import React from "react";

// Import the Education Logo Images
import LighthouseLabsImage from "../assets/education/lighthouselabs.png"
import QueensUniversityImage from "../assets/education/queensuniversity.png"

// Import education.css stylesheet
import "../styles/education.css"
 


// The Education component in the app
const Education = () => {

  // Array of Education Items
  const education = [
    {
      id: 1,
      src: LighthouseLabsImage,
      school: "Lighthouse Labs",
      degree: "Full Stack Web Development Program",
      location: "Toronto, Canada",
      year: "2022",
    },
    {
      id: 2,
      src: QueensUniversityImage,
      school: "Queen's University",
      degree: "Bachelor of Computing (Honours), Biomedical Computing",
      location: "Kingston, Ontario, Canada",
      year: "2011-2016",
    },
  ];



  // Return the Education component
  return (

    /* EDUCATION COMPONENT */
    /* Styled using Tailwind */
    <div name="education" className="w-full h-fit bg-white text-black pt-12 pb-12">

      {/* Title and Paragraph Container */}
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">

        {/* Education Header */}
        <p className="text-4xl font-bold">
          Education
        </p>

        {/* Use the map function to loop through education array using EJS */}
        {education.map(({ id, src, school, degree, location, year }) => (

          <div key={id} class="education-box">
            <div class="education-image-box">
              <img src={src} alt={school}/>
            </div>

            <div class="education-text-box">
              <div class="education-school">
                {school}
              </div>
              <div class="education-degree">
                {degree}
              </div>
              <div class="education-location">
                {location}
              </div>
              <div class="education-year">
                {year}
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
};



export default Education;


