import React from "react";



// The Contact component in the App
const Contact = () => {

  /* Return the Contact component */
  return (

    /* CONTACT COMPONENT */
    <div name="contact" className="w-full h-fit bg-white pt-12 pb-20">

      {/* Container for the Title, Description and Contact Form */}
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        
        {/* Title and Description Container */}
        <div className="pb-8">
          <p className="text-4xl font-bold inline">
            Contact
          </p>
          <p className="py-6 text-lg">
            Submit the form below to send me a message!
          </p>
        </div>

        {/* Container for the Contact Form */}
        <div className="flex justify-center items-center">

          {/* Email Contact Form */}
          {/* Email submissions are handled by GetForm.io, you can log in */}
          {/* https://docs.getform.io/installations/html-validations-attributes/ */}
          <form action="https://getform.io/f/02122911-e10c-4d4e-a5d7-ebb356e13288" method="POST" className=" flex flex-col w-full md:w-2/3">

            {/* Name Text Input*/}
            <input
              type="text"
              name="name"
              minlength="2"
              maxlength="50"
              placeholder="Enter Your Name"
              required
              className="p-2 bg-transparent border-2 rounded-md text-black focus:outline-none"
            />

            {/* Email Text Input */}
            <input
              type="email"
              name="email"
              minlength="7"
              maxlength="50"
              placeholder="Enter Your Email"
              required
              className="my-4 p-2 bg-transparent border-2 rounded-md text-black focus:outline-none"
            />

            {/* Message Text Area */}
            <textarea
              name="message"
              placeholder="Enter Your Message"
              rows="10"
              minlength="20"
              required
              className="p-2 bg-transparent border-2 rounded-md text-black focus:outline-none"
            />

            {/* Submit Email Button */}
            <button className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">
              Submit
            </button>

          </form>
        </div>
      </div>
    </div>
  );
};



export default Contact;
