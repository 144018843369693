import React from "react";

// Import Images
import ProfileImage from "../assets/home/michael-wang.jpg"
import GithubImage from "../assets/home/github.png"
import LinkedInImage from "../assets/home/linkedin.png"


// Import home.css stylesheet
import "../styles/home.css"



// The Home component in the App
const Home = () => {

  /* Return the Home component */
  return (

    /* HOME COMPONENT */
    /* Styled using Tailwind */
    <div name="home" className="h-fit w-full bg-white pt-2 md:pt-24 md:pb-24">


      <div class="home-box">
        <div class="home-text-box">

          <div class="home-name">
            Michael Wang
          </div>

          <div class="home-title">
            Full Stack Developer
          </div>

          <div class="home-bio">
            I am a full stack developer from Toronto, Canada. I have experience in both web and iOS development and enjoy designing user interfaces.
          </div>

          <div class="home-link-box">
            <a href="https://www.linkedin.com/in/michaelwangresume">
              <img class="home-link-icon" src={LinkedInImage} alt="LinkedIn"/>
            </a>
            <a href="https://github.com/michaelwangcode">
              <img class="home-link-icon" src={GithubImage} alt="Github"/>
            </a>
          </div>
        </div>

        <div class="home-photo-box">
          <img src={ProfileImage} alt="my profile"/>
        </div>

      </div>
    </div>
  )
};



export default Home;
